import React, { useEffect, useState } from 'react';
import SectionHeading from '../../common/SectionHeading';
import starIcon from './../../../Assets/star.png';
import boatIcon from './../../../Assets/ph_boat.png';
import { ApplicationString } from '../../../Constants/applicationString';
import { cssUtils } from '../../../Utils/cssUtils';
const TopRecommend: React.FC = () => {
  const { destinationData, heading, unitData } = ApplicationString.destinations;

  const [isXL, setIsXL] = useState(window.innerWidth > 1535);

  useEffect(() => {
    const handleResize = () => {
      setIsXL(window.innerWidth > 1535);
    };

    window.addEventListener('resize', handleResize);

    // Check the width initially
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="w-full bg-slate-100">
      <div className="mx-auto py-8 lg:px-8 xl:px-20 lg:py-15 px-8">
        <div className="flex xl:justify-center">
          <div className=" xl:w-150">
            <SectionHeading
              title={heading.title}
              descTextColor={cssUtils.colors.slate}
              description={heading.description}
            />
          </div>
        </div>
        {isXL ? (
          <div className="grid grid-cols-1 xl:items-center gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 justify-center ">
            <div className="xl:grid gap-6">
              {destinationData.slice(0, 3).map((destination, index) =>
                destination?.title ? (
                  <div
                    key={index}
                    className="h-auto flex items-center  shadow-lg rounded-2xl bg-white"
                  >
                    <div className="py-2 sm:py-2 sm:mx-2">
                      <div className="flex h-fit w-full sm:flex-row gap-4 items-center">
                        <img
                          className="h-50 min-w-40 max-w-40 rounded-2xl object-cover"
                          src={destination.imageUrl}
                          alt={destination.title}
                        />
                        {destination.title && (
                          <div className="flex flex-col gap-2 sm:gap-4">
                            <p className="font-semibold text-xl sm:text-2xl leading-tight text-blue-950">
                              {destination.title}
                            </p>
                            <div className="flex gap-1 items-center">
                              <img className="" src={boatIcon} />
                              <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                                {destination.vehicleCount} {unitData.Vehicles}
                              </p>
                            </div>
                            <div className="flex gap-1 items-center">
                              <img className="" src={starIcon} />
                              <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                                {destination.rating}/{unitData.maxRating}
                              </p>
                            </div>
                            <p className="font-semibold text-md sm:text-xl leading-tight text-blue-950">
                              {destination.linkText}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="flex w-full sm:flex-row flex-col gap-4 sm:items-center"
                  >
                    <img
                      className="h-40 w-full rounded-2xl object-cover"
                      src={destination.imageUrl}
                    />
                  </div>
                )
              )}
            </div>
            <div className="xl:grid gap-6">
              {destinationData.slice(3, 5).map((destination, index) =>
                destination?.title ? (
                  <div
                    key={index}
                    className=" h-fit flex items-center  shadow-lg rounded-xl bg-white"
                  >
                    <div className="py-2 sm:py-2 sm:mx-2">
                      <div className="flex h-fit w-full sm:flex-row gap-4 items-center">
                        <img
                          className="h-50 min-w-40 max-w-40  rounded-2xl object-cover"
                          src={destination.imageUrl}
                          alt={destination.title}
                        />
                        {destination.title && (
                          <div className="flex flex-col gap-2 sm:gap-4 ">
                            <p className="font-semibold text-xl sm:text-2xl leading-tight text-blue-950">
                              {destination.title}
                            </p>
                            <div className="flex gap-1 items-center">
                              <img className="" src={boatIcon} />
                              <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                                {destination.vehicleCount} {unitData.Vehicles}
                              </p>
                            </div>
                            <div className="flex gap-1 items-center">
                              <img className="" src={starIcon} />
                              <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                                {destination.rating}/{unitData.maxRating}
                              </p>
                            </div>
                            <p className="font-semibold text-md sm:text-xl leading-tight text-blue-950">
                              {destination.linkText}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="flex w-full sm:flex-row flex-col gap-4 sm:items-center"
                  >
                    <img
                      className="h-40 w-full rounded-2xl object-cover"
                      src={destination.imageUrl}
                    />
                  </div>
                )
              )}
            </div>
            <div className="xl:grid gap-6">
              {destinationData.slice(5, 7).map((destination, index) =>
                destination?.title ? (
                  <div
                    key={index}
                    className="h-fit flex items-center  shadow-lg rounded-2xl bg-white"
                  >
                    <div className="py-2 sm:py-2 sm:mx-2">
                      <div className="flex h-fit w-full sm:flex-row gap-4 items-center">
                        <img
                          className="h-50 min-w-40 max-w-40  rounded-2xl object-cover"
                          src={destination.imageUrl}
                          alt={destination.title}
                        />
                        {destination.title && (
                          <div className="flex flex-col gap-2 sm:gap-4 ">
                            <p className="font-semibold text-xl sm:text-2xl leading-tight text-blue-950">
                              {destination.title}
                            </p>
                            <div className="flex gap-1 items-center">
                              <img className="" src={boatIcon} />
                              <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                                {destination.vehicleCount} {unitData.Vehicles}
                              </p>
                            </div>
                            <div className="flex gap-1 items-center">
                              <img className="" src={starIcon} />
                              <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                                {destination.rating}/{unitData.maxRating}
                              </p>
                            </div>
                            <p className="font-semibold text-md sm:text-xl leading-tight text-blue-950">
                              {destination.linkText}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="flex w-full sm:flex-row flex-col gap-4 sm:items-center"
                  >
                    <img
                      className="h-40 w-full rounded-2xl object-cover"
                      src={destination.imageUrl}
                    />
                  </div>
                )
              )}
            </div>
            <div className="xl:grid gap-6">
              {destinationData.slice(7, 10).map((destination, index) =>
                destination?.title ? (
                  <div
                    key={index}
                    className="h-auto flex items-center  shadow-lg rounded-2xl bg-white"
                  >
                    <div className="py-2 sm:py-2 sm:mx-2">
                      <div className="flex h-fit w-full sm:flex-row gap-4 items-center">
                        <img
                          className="h-50 min-w-40 max-w-40  rounded-2xl object-cover"
                          src={destination.imageUrl}
                          alt={destination.title}
                        />
                        {destination.title && (
                          <div className="flex flex-col gap-2 sm:gap-4 ">
                            <p className="font-semibold text-xl sm:text-2xl leading-tight text-blue-950">
                              {destination.title}
                            </p>
                            <div className="flex gap-1 items-center">
                              <img className="" src={boatIcon} />
                              <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                                {destination.vehicleCount} {unitData.Vehicles}
                              </p>
                            </div>
                            <div className="flex gap-1 items-center">
                              <img className="" src={starIcon} />
                              <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                                {destination.rating}/{unitData.maxRating}
                              </p>
                            </div>
                            <p className="font-semibold text-md sm:text-xl leading-tight text-blue-950">
                              {destination.linkText}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="flex w-full sm:flex-row flex-col gap-4 sm:items-center"
                  >
                    <img
                      className="h-40 w-full rounded-2xl object-cover"
                      src={destination.imageUrl}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 xl:items-center gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 justify-center ">
            {destinationData.slice(0, 9).map((destination, index) =>
              destination?.title ? (
                <div
                  key={index}
                  className="h-auto flex items-center  shadow-lg rounded-2xl bg-white"
                >
                  <div className="py-2 3xsm:w-full sm:py-2 mx-2 sm:mx-2">
                    <div className="flex 3xsm:flex-col 2xsm:flex-row h-fit w-full sm:flex-row 3xsm:gap-1 2xsm:gap-4 3xsm:items-start 2xsm:items-center">
                      <img
                        className="2xsm:h-50 3xsm:min-h-40 3xsm:h-30 3xsm:min-w-full 3xsm:w-full 2xsm:w-auto 3xsm:max-w-full 2xsm:max-w-50 2xsm:min-w-40 min-w-40 min-h-50 max-h-50 w-auto rounded-2xl object-cover"
                        src={destination.imageUrl}
                        alt={destination.title}
                      />
                      {destination.title && (
                        <div className="flex flex-col gap-2 sm:gap-4 3xsm:p-2 2xsm:p-0">
                          <p className="font-semibold text-xl sm:text-2xl leading-tight text-blue-950">
                            {destination.title}
                          </p>
                          <div className="flex gap-1 items-center">
                            <img className="" src={boatIcon} />
                            <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                              {destination.vehicleCount} {unitData.Vehicles}
                            </p>
                          </div>
                          <div className="flex gap-1 items-center">
                            <img className="" src={starIcon} />
                            <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                              {destination.rating}/{unitData.maxRating}
                            </p>
                          </div>
                          <p className="font-semibold text-md sm:text-xl leading-tight text-blue-950">
                            {destination.linkText}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  key={index}
                  className="flex hidden w-full sm:flex-row flex-col gap-4 sm:items-center"
                >
                  <img
                    className="h-40 w-full rounded-2xl object-cover"
                    src={destination.imageUrl}
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopRecommend;
