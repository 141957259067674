import React from 'react';
import HeroSectionComponent from '../UserComponent/HeroSection';
import MobileSectionComponent from '../common/PlayStoreSection';
import PriceCardComponent from '../UserComponent/PricingCard';
import ExploreCardComponent from '../UserComponent/Cards';
import Accordion from '../common/Accordian';
import ListingCard from '../common/CreateListingCard';
import { ApplicationString } from '../../Constants/applicationString';
const HomeComponent: React.FC = () => {
  const userlistingCard = ApplicationString.userlistingCard;
  return (
    <React.Fragment>
      <HeroSectionComponent />
      <div className="bg-slate-200">
        <MobileSectionComponent />
      </div>
      <PriceCardComponent />
      <ExploreCardComponent />
      <Accordion />
      <ListingCard
        dataTestId={'UserListingButton'}
        backgroundImage={userlistingCard.backgroundImage}
        title={userlistingCard.title}
        description={userlistingCard.description}
        buttonText={userlistingCard.buttonText}
        buttonOnClick={() => {
          // Handle button click action
        }}
      />
    </React.Fragment>
  );
};

export default HomeComponent;
