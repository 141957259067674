import React from 'react';
import SectionHeading from '../../common/SectionHeading';
import pinIcon from './../../../Assets/Svgs/pinIcon.png';
import { ApplicationString } from '../../../Constants/applicationString';
import { cssUtils } from '../../../Utils/cssUtils';

const PopularListing: React.FC = () => {
  const { heading, listingData, unitData } = ApplicationString.ownerListing;
  return (
    <React.Fragment>
      <div className="w-full">
        <div className={cssUtils.layout.sectionLayout}>
          <SectionHeading
            title={heading.title}
            // description={heading.description}
            descTextColor={cssUtils.colors.slate}
          />
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-4 justify-center mt-5">
            {listingData.map((listing) => (
              <div
                key={listing.id}
                className="border border-slate-200 shadow-lg rounded-2xl"
              >
                <div className="flex sm:flex-row flex-col gap-4 sm:items-center p-4 pb-0 sm:p-2">
                  <img
                    className="h-60 sm:h-30 w-full min-w-30 sm:w-60 xl:min-30 rounded-2xl object-cover"
                    src={listing.imageUrl}
                    alt={listing.name}
                  />
                  <div className="flex flex-col gap-2 sm:gap-4 py-2 pr-4 sm:py-4">
                    <p className="font-semibold text-xl leading-tight text-blue-950">
                      {listing.name}
                    </p>
                    <div className="flex gap-1 items-center">
                      <img className="h-5 w-6" src={pinIcon} />
                      <p className="font-sans font-normal text-slate-600 text-base leading-tight">
                        {listing.location}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4 p-4">
                  <div>
                    <p className="text-slate-600">
                      <strong className="text-black-2">{listing.cabins}</strong>{' '}
                      {unitData.cabins} |{' '}
                      <strong className="text-black-2">
                        {listing.capacity}
                      </strong>{' '}
                      {unitData.personCapacity} |{' '}
                      <strong className="text-black-2">{listing.length}</strong>{' '}
                      {unitData.length}
                    </p>
                  </div>
                  <div>
                    <p className="font-sans text-slate-600 font-normal text-base leading-tight">
                      {listing.description}
                    </p>
                  </div>
                  <div>
                    <button
                      className="bg-blue-100 rounded-xl p-4 py-3 text-lg"
                      data-testid={`listing-price-button-${listing.id}`}
                    >
                      <strong className="text-primaryBlue">
                        {unitData.currency} {listing.price.toFixed(2)}{' '}
                      </strong>
                      / {unitData.hour}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PopularListing;
