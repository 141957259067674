import React, { ReactNode } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import NewsLetterComponent from '../NewsLetter';

const DefaultLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark flex flex-col min-h-screen">
      <Header />
      <div className="mt-20 lg:mt-24 flex-1 min-h-0">{children}</div>
      <NewsLetterComponent />
      <Footer />
    </div>
  );
};

export default DefaultLayout;
