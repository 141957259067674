import React from 'react';
import Masonry from 'react-masonry-css';
import SectionHeading from '../../common/SectionHeading';
import { ApplicationString } from '../../../Constants/applicationString';
import { cssUtils } from '../../../Utils/cssUtils';

const TestimonialComponent: React.FC = () => {
  const { testimonials, heading } = ApplicationString.testimonials;

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <div className="w-full bg-slate-200 py-4">
      <div className={cssUtils.layout.sectionLayout}>
        <SectionHeading
          title={heading.title}
          // description={heading.description}
          descTextColor={cssUtils.colors.slate}
        />
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="flex gap-6 mt-6"
          columnClassName="masonry-grid_column"
        >
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="break-inside h-auto flex w-full items-center shadow-lg rounded-2xl bg-white mb-6"
            >
              <div className="p-6 flex flex-col gap-6">
                <div className="flex flex-col gap-1">
                  <div className="font-semibold text-4xl">{`"`}</div>
                  <div>
                    <p className="font-sans text-slate-600 font-normal text-base leading-tight">
                      {testimonial.quote}
                    </p>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <img
                    className="h-20 w-20 rounded-2xl object-cover"
                    src={testimonial.image}
                    alt="Testimonial"
                  />
                  <div className="flex flex-col gap-0 sm:gap-0 py-2 sm:py-4">
                    <p className="font-semibold text-md sm:text-xl leading-tight text-blue-950">
                      {testimonial.author}
                    </p>
                    <div className="flex gap-1 items-center">
                      <p className="font-sans text-sm font-normal text-slate-600 sm:text-sm leading-tight">
                        {testimonial.position}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      </div>
    </div>
  );
};

export default TestimonialComponent;
