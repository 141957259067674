import React, { useState } from 'react';
import SectionHeading from '../SectionHeading';
import { ApplicationString } from '../../../Constants/applicationString';
import { cssUtils } from '../../../Utils/cssUtils';

const Accordion: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const accordionData = ApplicationString.accordion;

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full">
      <div className={cssUtils.layout.sectionLayout}>
        <SectionHeading
          title={accordionData.title}
          // description={accordionData.description}
          descTextColor={cssUtils.colors.slate}
        />
        <div id="accordion-collapse" data-accordion="collapse">
          {accordionData.accordionData.map((item) => (
            <div key={item.id}>
              <h2 id={`accordion-collapse-heading-${item.id}`}>
                <button
                  type="button"
                  className={`flex outline-none my-2 items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 rounded-xl dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3 ${activeIndex === item.id ? 'bg-black text-white' : 'bg-white border-slate-300'}`}
                  data-accordion-target={`#accordion-collapse-body-${item.id}`}
                  aria-expanded={activeIndex === item.id ? 'true' : 'false'}
                  aria-controls={`accordion-collapse-body-${item.id}`}
                  data-testid={`accordion-button-${item.id}`}
                  onClick={() => handleClick(item.id)}
                >
                  <span className="font-medium text-md text-start leading-tight">
                    {item.question}
                  </span>
                  {activeIndex === item.id ? (
                    <svg
                      data-accordion-icon
                      className={`w-3 h-3 rotate-0 shrink-0`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  ) : (
                    <svg
                      data-accordion-icon
                      className={`w-3 h-3 rotate-180 shrink-0`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5 5 1 1 5"
                      />
                    </svg>
                  )}
                </button>
              </h2>
              <div
                id={`accordion-collapse-body-${item.id}`}
                className={activeIndex === item.id ? 'block' : 'hidden'}
                aria-labelledby={`accordion-collapse-heading-${item.id}`}
              >
                <div className="p-5 border my-2 rounded-xl border-slate-300 dark:border-gray-700 dark:bg-gray-900">
                  <p className=" text-slate-600 dark:text-gray-400 font-medium">
                    <div dangerouslySetInnerHTML={{ __html: item.answer }} />{' '}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
