import React from 'react';
import { ApplicationString } from '../../../Constants/applicationString';
import { cssUtils } from '../../../Utils/cssUtils';

const ComingSoon: React.FC = () => {
  return (
    <div className={`!py-0 ${cssUtils.layout.sectionLayout}`}>
      <div className="flex flex-col items-center justify-center min-h-screen text-center bg-gray-100">
        <h1 className="text-4xl font-bold mb-2">
          {ApplicationString.comingSoonPage.comingSoon.title}
        </h1>
        <p className="text-lg text-gray-600">
          {ApplicationString.comingSoonPage.comingSoon.text}
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
