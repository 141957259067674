import React, { useState } from 'react';
import { ApplicationString } from '../../../Constants/applicationString';
import bgNewsLetterImg from './../../../Assets/bgNewsletter.png';
import { cssUtils } from '../../../Utils/cssUtils';
import { ApiRequest } from '../../../Api/api';
import { HttpStatusCode } from 'axios';
import { URLS } from '../../../Constants/urls';
import { emailPattern } from '../../../Utils/utils';
import LoadingButton from '../LoadingButton/LoadingButton';

const NewsLetterComponent: React.FC = () => {
  const newsletterContent = ApplicationString.newsletterForm;

  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleNewsletterInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmail(e.target.value);
  };

  const subscribeEmail = async (email: string) => {
    try {
      setIsSubmitting(true);
      const res = await ApiRequest.post(URLS.NEWS_LETTER, {
        email,
      });
      if (res?.status === HttpStatusCode.Created) {
        setEmail('');
      }
    } catch (error) {
      console.error('Subscription failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    try {
      await subscribeEmail(email);
      setIsSubscribed(true);
      setEmail('');
    } catch (error) {
      console.error('Subscription failed:', error);
    }
  };

  return (
    <React.Fragment>
      <div className="relative w-full mt-15 mb-15">
        <div className="mx-8">
          <form
            data-testid="form-newsletter"
            onSubmit={handleSubmit}
            className=" bg-slate-800 rounded-3xl mx-auto flex max-w-7xl items-center justify-between relative"
            style={{
              backgroundImage: `url(${bgNewsLetterImg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              objectFit: 'contain',
            }}
          >
            <div className="3xsm:py-6 3xsm:px-6 xsm:px-8 xsm:py-8 lg:px-12 lg:py-12 lg:flex flex flex-col lg:flex-row gap-4 w-full justify-between items-center relative z-10">
              <div className="text-white">
                <p className="font-semibold text-3xl lg:text-3xl xl:text-4xl leading-tight">
                  {newsletterContent.title}
                </p>
                <p className="sm:text-base text-mb leading-normal">
                  {newsletterContent.description}
                </p>
              </div>
              {!isSubscribed ? (
                <div className="sm:w-auto max-w-80 lg:max-w-auto xl:w-auto sm:max-w-fit w-full flex-col flex lg:flex lg:flex-row gap-2">
                  <input
                    className="text-sm border-0 lg:w-80 rounded-full p-4 py-3"
                    type="email"
                    placeholder={
                      ApplicationString.newsletterForm.placeHolder.email
                    }
                    value={email}
                    name="newsletter-input"
                    data-testid="newsletter-input"
                    onChange={handleNewsletterInputChange}
                    pattern={emailPattern}
                    title={ApplicationString.newsletterForm.inputTitle.email}
                    required
                  />
                  {isSubmitting ? (
                    <LoadingButton
                      buttonText={
                        ApplicationString.newsletterForm.buttonSubscribing
                      }
                    />
                  ) : (
                    <button
                      data-testid="subscribe-button"
                      className={cssUtils.button.secondary}
                      type="submit"
                    >
                      {newsletterContent.buttonText}
                    </button>
                  )}
                </div>
              ) : (
                <p className="font-semibold xl:text-2xl leading-tight text-white sm:text-center sm:text-xl">
                  {newsletterContent.successMessage}
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewsLetterComponent;
