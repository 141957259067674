import React from 'react';
import HomeContainer from '../../Containers/Home';
const HomeScreen: React.FC = () => {
  return (
    <React.Fragment>
      <HomeContainer />
    </React.Fragment>
  );
};

export default HomeScreen;
