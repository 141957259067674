import React from 'react';
import HomeComponent from '../../Components/Home';
const HomeContainer: React.FC = () => {
  return (
    <React.Fragment>
      <HomeComponent />
    </React.Fragment>
  );
};

export default HomeContainer;
