import React from 'react';
import SectionHeading from '../../common/SectionHeading';
import { ApplicationString } from '../../../Constants/applicationString';
import { cssUtils } from '../../../Utils/cssUtils';
import { useNavigate } from 'react-router-dom';
import { InternalRoute } from '../../../Utils/internalRoutes';

const PriceCardComponent: React.FC = () => {
  const {
    title,
    // description,
    buttonText,
    cards,
  } = ApplicationString.priceCard;
  const Navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="w-full">
        <div className={cssUtils.layout.sectionLayout}>
          <div className="xl:flex items-center gap-4 justify-between">
            <SectionHeading
              title={title}
              // description={description}
              descTextColor={cssUtils.colors.slate}
            />
            <button
              onClick={() => Navigate(InternalRoute.Home)}
              className={cssUtils.button.primary}
              data-testid="priceCard-List-vehicle-button"
            >
              {buttonText}
            </button>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 justify-center mt-5 sm:mt-2">
            <div
              className={`bg-blue-100 border border-blue-200 rounded-xl p-4 flex flex-col gap-3`}
            >
              <div
                className={`text-2xl lg:text-2xl xl:text-4xl text-primaryBlue`}
              >
                $
              </div>
              <p className={`text-blue-500 text-sm sm:text-sm`}>
                {cards[0].description}
              </p>
              <p className={` text-2xl lg:text-2xl  xl:text-4xl text-blue-800`}>
                <strong>{cards[0].amount}</strong>
              </p>
            </div>
            <div
              className={`bg-slate-100 border border-slate-200 rounded-xl p-4 flex flex-col gap-3`}
            >
              <div
                className={`text-2xl lg:text-2xl xl:text-4xl text-slate-600`}
              >
                $
              </div>
              <p className={`text-slate-500 text-sm sm:text-sm`}>
                {cards[1].description}
              </p>
              <p
                className={` text-2xl  lg:text-2xl  xl:text-4xl text-slate-800`}
              >
                <strong>{cards[1].amount}</strong>
              </p>
            </div>
            <div
              className={`bg-green-100 border border-green-200 rounded-xl p-4 flex flex-col gap-3`}
            >
              <div
                className={`sm:text-xl lg:text-2xl xl:text-4xl text-green-600`}
              >
                $
              </div>
              <p className={`text-green-500 text-sm sm:text-sm`}>
                {cards[2].description}
              </p>
              <p
                className={`text-2xl  lg:text-2xl  xl:text-4xl text-green-800`}
              >
                <strong>{cards[2].amount}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PriceCardComponent;
