import React from 'react';
import OwnerComponent from '../../Components/Owner';
const OwnerContainer: React.FC = () => {
  return (
    <React.Fragment>
      <OwnerComponent />
    </React.Fragment>
  );
};

export default OwnerContainer;
