import React from 'react';
import OwnerContainer from '../../Containers/Owner';
const OwnerScreen: React.FC = () => {
  return (
    <React.Fragment>
      <OwnerContainer />
    </React.Fragment>
  );
};

export default OwnerScreen;
