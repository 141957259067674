import React from 'react';
import { ApplicationString } from '../../../Constants/applicationString';
const HeroSectionComponent: React.FC = () => {
  const userHeroSection = ApplicationString.userHeroSection;
  return (
    <React.Fragment>
      <div className="w-full bg-slate-100">
        <div className="mx-auto max-w-7xl px-6 py-5 lg:px-8 xl:px-0 lg:py-15">
          <div className="flex flex-col lg:flex-row items-center">
            <div className=" inset-10 flex-col gap-4 flex mx-auto text-black px-6 py-8 lg:px-16 lg:py-4">
              <p className="text-2xl lg:text-3xl xl:text-5xl flex leading-tight">
                {userHeroSection.title}
              </p>
              <p className="font-semibold text-2xl lg:text-3xl xl:text-5xl leading-tight">
                {userHeroSection.subtitle}
              </p>
              <p className="text-sm sm:text-lg xl:text-xl text-slate-600 xl:w-150 leading-tight">
                {/* {userHeroSection.description} */}
              </p>
            </div>
            <div>
              <img
                className="sm:h-150 h-80 object-contain"
                src={userHeroSection.image}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeroSectionComponent;
