import React from 'react';
import Logo from './../../../Assets/Images/logo-min.png';
import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { ApplicationString } from '../../../Constants/applicationString';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { InternalRoute } from '../../../Utils/internalRoutes';
import { cssUtils } from '../../../Utils/cssUtils';

const Header: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const Navigate = useNavigate();
  const location = useLocation();

  const handleMobileMenu = (route: string) => {
    Navigate(route);
    setMobileMenuOpen(false);
  };

  const renderButtons = () => {
    if (location.pathname === InternalRoute.Home) {
      return (
        <>
          <button
            onClick={() => handleMobileMenu(InternalRoute.Owner)}
            data-testid="become-an-owner-button"
            className={cssUtils.button.secondary}
          >
            {ApplicationString.header.button.Owner}
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            onClick={() => handleMobileMenu(InternalRoute.Home)}
            data-testid="rent-vehicle-button"
            className={cssUtils.button.secondary}
          >
            {ApplicationString.header.button.rentVehicle}
          </button>
          {/* <button
            className={cssUtils.button.secondary}
            data-testid="list-my-vehicle-button"
          >
            {ApplicationString.header.button.listMyVehicle}
          </button> */}
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <header className="bg-white fixed top-0 w-full left-0 z-20 xl:z-20">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 xl:px-0 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <Link to={InternalRoute.Home} className="-m-1.5 p-1.5">
              <img className="h-8 w-auto" src={Logo} alt="" />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
              data-testid="mobile-menu-open"
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-5">
            {renderButtons()}
          </div>
        </nav>
        <Dialog
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-20 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <img className="h-8 w-auto" src={Logo} alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
                data-testid="mobile-menu-close"
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-12 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="py-12 flex-col flex gap-5">
                  {renderButtons()}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </React.Fragment>
  );
};

export default Header;
