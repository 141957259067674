import React, { Fragment, useEffect } from 'react';
import {
  type NavigateFunction,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ProtectedRoute from './protectedRoutes';
import {
  getUserDetailsFromLocalStorage,
  removeLocalStorageAccountInfo,
} from '../Utils/utils';
import { RouteConfig } from './appRouteConfig';

const AppRouter: React.FC = () => {
  const userID: string | null = getUserDetailsFromLocalStorage('userID');
  const navigate: NavigateFunction = useNavigate();
  const match = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (match.pathname === '/logout') {
      removeLocalStorageAccountInfo();
      navigate('/login');
    }
  }, [match]);

  return (
    <>
      <Fragment>
        <Routes>
          {RouteConfig?.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute
                  allowedLayout={route.allowedLayout}
                  isProtectedRoute={route.isProtectedRoute}
                  userID={userID}
                  element={route.element}
                  allowedRoles={route.allowedRoles}
                />
              }
            />
          ))}
        </Routes>
      </Fragment>
    </>
  );
};

export default AppRouter;
