import React from 'react';

import SectionHeading from '../../common/SectionHeading';
import { ApplicationString } from '../../../Constants/applicationString';
import Img from './../../../Assets/backgroundImg.png';
import { cssUtils } from '../../../Utils/cssUtils';
import { useNavigate } from 'react-router-dom';
import { InternalRoute } from '../../../Utils/internalRoutes';

const ExploreCardComponent: React.FC = () => {
  const featureCards = ApplicationString.featureCards;
  const Navigate = useNavigate();
  return (
    <div
      className="w-full bg-black"
      style={{
        backgroundImage: `url(${Img})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <div className={cssUtils.layout.sectionLayout}>
        <div className="xl:flex items-center gap-4 justify-between text-white">
          <SectionHeading
            title={featureCards.title}
            descTextColor="text-white"
            // description={featureCards.description}
          />
          <button
            onClick={() => Navigate(InternalRoute.Home)}
            className={cssUtils.button.secondary}
            data-testid="ExploreCard-List-vehicle-button"
          >
            List My Vehicle
          </button>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 justify-center mt-5 sm:mt-2">
          {featureCards.featureData.map((card, index) => (
            <div key={index} className="bg-white rounded-xl">
              <div className="p-6 flex flex-col gap-2">
                <div>
                  <img className="" src={card.icon} alt="" />
                </div>
                <p className="font-semibold text-2sm xl:text-2xl leading-tight text-blue-950">
                  {card.title}
                </p>
                <p className="font-normal sm:text-sm xl:text-base text-slate-500 leading-tight">
                  {/* {card.description} */}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExploreCardComponent;
