export const commonConfig = {
  ApiUrl: process.env.REACT_APP_API_URL,
  CompanyName: 'CompanyName',
  CompanyNameSmall: 'CompanyNameSmall',
  Companyaddress: 'Companyaddress',
  Country: 'Country',
  CompanyContactNumber: 'CompanyContactNumber',
  CompanyEmail: 'CompanyEmail',
  CompanyWebsite: 'CompanyWebsite',
  CopyrightText: `© ${new Date().getFullYear()} Onelife. All rights reserved.`,
};
