import React from 'react';
import mobileImg from './../../../Assets/mobile-min.png';
import PlayStore from './../../../Assets/playStore.png';
import IosStore from './../../../Assets/iosStore.png';
import { ApplicationString } from '../../../Constants/applicationString';
const MobileSectionComponent: React.FC = () => {
  const mobileSection = ApplicationString.mobileSection;
  return (
    <React.Fragment>
      <div className="w-full">
        <div className="mx-auto max-w-7xl px-6 xl:px-0">
          <div className="sm:flex-row flex flex-col sm:pb-0 pb-10 items-center sm:mx-10 xl:mx-20">
            <div className="">
              <img className="object-cover sm:h-auto" src={mobileImg} />
            </div>
            <div className="flex flex-col gap-8 mt-8">
              <div>
                <p className="font-semibold text-xl sm:text-2xl xl:text-4xl leading-tight">
                  {mobileSection.title}
                </p>
                <p className="font-normal text-slate-800 text-sm sm:text-lg xl:text-xl leading-tight">
                  {mobileSection.description}
                </p>
              </div>
              <div className="mt-auto flex gap-4 ">
                <img
                  className="3xsm:h-9 2xsm:h-11.5 xsm:h-13 sm:h-12 xl:h-14 w-fit cursor-pointer"
                  src={PlayStore}
                  alt=""
                />
                <img
                  className="3xsm:h-9 2xsm:h-11.5 xsm:h-13 sm:h-12 xl:h-14 w-fit cursor-pointer"
                  src={IosStore}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MobileSectionComponent;
