import React from 'react';
import BgImg from './../../../Assets/Images/bgCreateListing-min.png';

interface ListingCardProps {
  backgroundImage?: string;
  title: string;
  description: string;
  buttonText: string;
  dataTestId: string;
  buttonOnClick: () => void;
}

const ListingCard: React.FC<ListingCardProps> = ({
  // backgroundImage,
  title,
  description,
  buttonText,
  dataTestId,
  buttonOnClick,
}) => {
  return (
    <div className="relative bg-slate-100 w-full">
      <div className="mx-8 py-10">
        <div
          className="text-center flex-col gap-6 flex bg-slate-800 rounded-3xl mx-auto max-w-7xl items-center justify-between text-white relative  px-6 py-8 lg:px-16 lg:py-16"
          style={{
            backgroundImage: `url(${BgImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            objectFit: 'contain',
          }}
        >
          <p className=" text-3xl lg:text-3xl font-semibold xl:text-4xl leading-tight text-center">
            {title}
          </p>
          <p className=" text-mb sm:text-xl xl:text-xl leading-tight text-center">
            {description}
          </p>
          <button
            className="text-sm font-semibold  bg-white text-black border-0 p-4 py-3
rounded-full"
            onClick={buttonOnClick}
            data-testid={dataTestId}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
