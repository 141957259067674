/*---- Common Tailwind Class Utils ----*/

export const cssUtils = {
  inputClasses:
    'w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary',
  labelClasses: 'mb-2.5 block font-medium text-black dark:text-white',
  button: {
    primary:
      'text-sm font-semibold bg-white px-6 text-black border p-4 py-3 rounded-full shadow-md',
    secondary:
      'text-sm font-semibold bg-primaryBlue px-6 text-white p-4 py-3 rounded-full shadow-md',
    tertiary: 'bg-tertiary text-white',
    disabled: 'bg-disabled text-disabled',
  },
  layout: {
    sectionLayout: 'mx-auto max-w-7xl px-6 py-8 lg:px-8 xl:px-0 lg:py-15',
  },
  colors: {
    slate: 'slate',
    white: 'white',
  },
};
