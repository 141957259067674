import React from 'react';
import { useNavigate } from 'react-router-dom';
const Notfound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <button
      style={{ background: '#1677ff' }}
      onClick={() => {
        navigate('/');
      }}
    >
      Back Home
    </button>
  );
};

export default Notfound;
