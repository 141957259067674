import React from 'react';
import SectionHeading from '../../common/SectionHeading';
import MobileSectionComponent from '../../common/PlayStoreSection';
import { ApplicationString } from '../../../Constants/applicationString';
import { cssUtils } from '../../../Utils/cssUtils';

const PopularCategories: React.FC = () => {
  const { heading, categories } = ApplicationString.popularCategories;

  return (
    <React.Fragment>
      <div className="w-full bg-slate-100">
        <div className={cssUtils.layout.sectionLayout}>
          <SectionHeading
            title={heading.title}
            // description={heading.description}
            descTextColor={cssUtils.colors.slate}
          />
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 justify-center mt-10 ">
            {categories.map((category) => (
              <div
                key={category.id}
                className="bg-slate-800 h-60 sm:max-h-100 xl:min-h-100 xl:max-h-80 lg:min-h-80 rounded-3xl text-white relative "
              >
                <img
                  src={category.imageSrc}
                  alt="Image"
                  className="w-full h-60 sm:h-full object-cover rounded-3xl mb-6 opacity-60"
                />
                <div className="absolute inset-10 flex justify-center items-end">
                  <p className="font-semibold sm:text-sm lg:text-xl leading-tight text-center">
                    {category.title}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <MobileSectionComponent />
      </div>
    </React.Fragment>
  );
};

export default PopularCategories;
