import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userData: {},
    isUserLogin: false,
    userObjId: null,
    userRole: null,
  },
  reducers: {
    // setUserData
    getUserData(state, { payload }) {
      state.userData = payload;
      state.userRole = payload.role;
    },

    loginStatus(state, { payload }) {
      state.isUserLogin = payload;
    },
  },
});

export const { getUserData, loginStatus } = authSlice.actions;
export default authSlice.reducer;
