import React from 'react';

interface SectionHeadingProps {
  title: string;
  description?: string;
  descTextColor?: string;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  title,
  description,
  descTextColor,
}) => {
  return (
    <div className="flex flex-col  gap-2 mb-6">
      <p className="font-semibold text-xl sm:text-2xl xl:text-4xl leading-tight">
        {title}
      </p>
      <p
        className={`text-sm sm:text-lg xl:text-xl leading-tight text-${descTextColor}-600`}
      >
        {description}
      </p>
    </div>
  );
};

export default SectionHeading;
