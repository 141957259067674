import React from 'react';
import { Navigate } from 'react-router-dom';
import { IProtectedRoute } from '../Interfaces/interfaces';
import { getUserDetailsFromLocalStorage } from '../Utils/utils';
import DefaultLayout from '../Components/common/Layout/DefaultLayout';

const protectedRoutes: React.FC<IProtectedRoute> = ({
  element,
  allowedLayout,
  isProtectedRoute,
  userID,
  allowedRoles,
}: IProtectedRoute): React.ReactElement => {
  const checkLayoutType = (element: IProtectedRoute['element']) => {
    switch (allowedLayout) {
      case 'main':
        return <DefaultLayout>{element}</DefaultLayout>;
      case 'admin':
        return <DefaultLayout>{element}</DefaultLayout>;
      default:
        return element;
    }
  };

  const checkAuthorization = (
    routeElement: IProtectedRoute['element']
  ): React.ReactElement => {
    const userRole = getUserDetailsFromLocalStorage('Role');

    if (userID && userRole && allowedRoles.includes(userRole)) {
      return checkLayoutType(routeElement);
    }

    return <Navigate to={userID ? '/unauthorized' : '/login'} />;
  };

  return isProtectedRoute ? (
    checkAuthorization(element)
  ) : (
    <>{checkLayoutType(element)}</>
  );
};

export default protectedRoutes;
